import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {parserContent, placesForEvent, getValueFromVariable} from "../../helpers/Tools";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import "./page.css"
import {PageHeaderButtons} from "./Page.Header.Buttons";
import {store} from "../../store/store";

class PageBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const dyn_string = this.props.t;
        const {styles, dataPage} = this.props;
        let places_info;

        let my_content = (dataPage.type === "event" || dataPage.type === "announce") ? parserContent(dataPage.description): parserContent(dataPage.content);

        if(dataPage.type === "event"){
            let places = placesForEvent(this.props.dataPage.max_participation, this.props.dataPage.participants);

            if(isNaN(places)){
                places_info = <p>{dyn_string('planning_label_unlimited_spots')}</p>
            } else {
                places_info = (places === 0) ? <p>{dyn_string('planning_label_no_remaining_spots')}</p> : <p>{dyn_string("planning_label_remaining_spots", {remaining_spots: places})}</p>;
            }
        }

        return (
            <React.Fragment>
                <h1 style={{color: styles.navbar.color, fontSize: styles.navbar.fontSize, margin: styles.navbar.margin, textAlign: 'center'}}>
                    {dataPage.title}
                </h1>


                <PageHeaderButtons dataPage={dataPage} displayGuest={this.props.displayGuest} />

                <div style={{height: "5px"}}/>{" "}
                {/* temporaire pour gerer espacement des titres*/}

                {dataPage.subtitle && (
                    <h2 style={{color: styles.navbar.color, fontSize: styles.navbar.fontSize, margin: styles.navbar.margin, textAlign: 'center'}}>
                        {dataPage.subtitle}
                    </h2>
                )}

                {places_info}

                <div style={{ marginTop: "20px" }}>
                    {my_content}
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    styles: state.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PageBody);
