import {fetchGetContentConnector} from "../../api/api.connector";
import {store} from "../../store/store";
import md5 from "md5";
import {
    newDataContentAuthFromConnector,
    newDataContentAuthSsoFromConnector,
    newDataContentFromConnector
} from "../../actions";
import {clone, isJsonParsable, findSelectedInContents} from "../../helpers/Tools";

/**
 * Permet de gerer la réception de données d'un connecteur
 * Renvoi false pour stopper le loading (à changer quand loading via redux)
 * @param connectors
 * @param content
 * @param variableStorage
 * @returns {Promise<boolean>}
 */
export async function handleConnector(connectors, content, variableStorage){
    variableStorage = {...store.getState().variableStorage, ...variableStorage, ...content.list_variables};

    let connector_id = content.connector_id;
    let content_id = content.id
    let connector = connectors.find((element) => element.id === connector_id)
    let regEx = /(?:[{]{1})([a-zA-Z.0-9éèàê_-]*)(?:[}]{1})/gim;
    let new_url = connector.url;
    let new_params = connector.params;

    console.log("connector_id");
    console.log(connector_id);
    console.log("content");
    console.log(content);
    console.log("variableStorage");
    console.log(variableStorage);

/*    console.log("---URL---");
    console.log(new_url);
    console.log("---PARAMS---");
    console.log(new_params);*/

    if(regEx.test(connector.url)){

        let matches = connector.url.match(regEx)

        for(let match of matches){
            let one_matches = match.matchAll(regEx);

            for(let one_match of one_matches){
                if(one_match[1] in variableStorage){
                    new_url = variableStorage[one_match[1]] ? new_url.replaceAll(one_match[0], variableStorage[one_match[1]]) : new_url.replaceAll(one_match[0], "");
                } else {
                    new_url = "";
                }
            }
        }
    } else {
        new_url = connector.url
    }

    if(regEx.test(connector.params)){

        let matches = connector.params.match(regEx)

        console.log(matches);

        for(let match of matches){
            let one_matches = match.matchAll(regEx);

            for(let one_match of one_matches){
                /*if(one_match[1] in variableStorage){*/

                var matchSplited = one_match[1].split('.');
                var matchValue = "";
                if(matchSplited.length>1){
                    matchValue = variableStorage[matchSplited[0]][matchSplited[1]];
                }
                else {
                    matchValue = variableStorage[matchSplited[0]];
                }

                if(variableStorage[matchSplited[0]]){
                    console.log("REPLACE " + one_match[0] + " BY " + matchValue);
                    new_params = new_params.replaceAll(one_match[0], matchValue)
                }
                else {
                    console.log("REPLACE " + one_match[0] + " BY " + " R ");
                    new_params = new_params.replaceAll(one_match[0], "")
                }
            }
        }
    } else {
        new_params = connector.params
    }

/*    console.log("---NEW URL---");
    console.log(new_url);
    console.log("---NEW PARAMS---");
    console.log(new_params);*/

    if(new_url){

        //@todo le faire proprement
        if(store.getState().profil.mobinaute && store.getState().profil.mobinaute.accounts && store.getState().profil.mobinaute.accounts.zen_token){
            new_url = new_url.replaceAll("[%zen_token]", store.getState().profil.mobinaute.accounts.zen_token)
        }

        let axios_props = {
            type: connector.type,
            request_method: connector.request_method,
            request_method_body: connector.request_method_body,
            url: new_url,
            params: new_params,
            auth_type: connector.auth_type,
            auth_login: connector.auth_login,
            mapper_tree: connector.mapper_tree,
        }

        let connector_data = await fetchGetContentConnector(axios_props);

        if(connector_data.success){
            mappingAssociation(connector_data.data, connector.mapper_attributs, connector_id, content_id)

            return false;
        } else {
            return false;
        }
    } else {
        return false;
    }

}

/**
 *  Effectue le mapping entre les données d'un connecteur et le mapping_attributs d'un composant
 * @param resultsTree
 * @param init_mapper_attributs
 * @param mapper_tree
 * @param connector_id
 * @param parent_id
 * @returns {[]}
 */
export function mappingAssociation(resultsTree, init_mapper_attributs, connector_id, parent_id){

    let appId = store.getState().application.getApp.id;
    let mapper_attributs;

    if(typeof init_mapper_attributs !== "object"){
        mapper_attributs = JSON.parse(init_mapper_attributs)
    } else {
        mapper_attributs = init_mapper_attributs
    }

    if(!Array.isArray(resultsTree)){
        resultsTree = [resultsTree];
    }

    let body_array = [];

    if(Array.isArray(resultsTree)){
        resultsTree.forEach((elements, i) => {
            let element_array = {};

            Object.keys(mapper_attributs).forEach((attributs) => {
                let value = mapper_attributs[attributs];

                if(value){
                    if(typeof value === "object"){
                        element_array[attributs] = constructElementFromJson(value, elements)
                    } else {
                        if(value.match(/{([^}]*)}/gm)){
                            if(isJsonParsable(value)){
                                element_array[attributs] = constructElementFromJson(JSON.parse(value), elements)
                            } else {
                                let results_all = value.matchAll(/{([^}]*)}/gm)
                                let new_value;

                                for(let result_all of results_all){
                                    if(new_value){
                                        new_value = new_value.replaceAll(result_all[0], getValueInMultiArray(elements, result_all[1].split('.')))
                                    } else{

                                        var actualyValue = getValueInMultiArray(elements, result_all[1].split('.'))

                                        if(attributs === "list_variables"){
                                            new_value = actualyValue;
                                        }
                                        else {
                                            new_value = value.replaceAll(result_all[0], actualyValue)
                                        }
                                    }

                                    element_array[attributs] = new_value;
                                }
                            }
                        } else {

                            element_array[attributs] = value;
                        }
                    }
                } else {

                    element_array[attributs] = value;
                }
            });

            element_array['id'] = md5(appId+"_"+connector_id+"_"+Object.values(element_array))
            element_array["parent_id"] = parent_id
            element_array["nodes"] = []

            body_array[i] = element_array;
        })
    }

    const check_sso_contents = !!store.getState().contentauthSso.success
    const check_auth_contents = !!store.getState().contentauth.success
    const contents = check_auth_contents ? clone(store.getState().contentauth.result) : check_sso_contents ? clone(store.getState().contentauthSso.result) : clone(store.getState().content.result)

    let new_content = findSelectedInContents(contents, parent_id)

    if(new_content){

        if(new_content.type === "page"){
            new_content.content = body_array[0].content;
        }
        else if(new_content.type === "graphical_view"){
            new_content.content_tmp = body_array[0].content_tmp;
        }
        else {
            new_content.nodes = body_array
        }
        console.log(contents)
        console.log(new_content)
        if(check_sso_contents){
            store.dispatch(newDataContentAuthSsoFromConnector(contents))
        } else if(check_auth_contents){
            store.dispatch(newDataContentAuthFromConnector(contents))
        } else {
            store.dispatch(newDataContentFromConnector(contents))
        }
    }
}

export function constructElementFromJson(value, elements){
    let obj_tmp = Array.isArray(value) ? [] : {};

    Object.keys(value).forEach((data) => {
        if(typeof value[data] === "object"){
            obj_tmp[data] = constructElementFromJson(value[data], elements)
        } else {
            if(value[data].match(/{([^}]*)}/gm)){
                let results_all = value[data].matchAll(/{([^}]*)}/gm)
                let new_value;

                for(let result_all of results_all) {
                    if (new_value) {
                        new_value = new_value.replaceAll(result_all[0], getValueInMultiArray(elements, result_all[1].split('.')))
                    } else {
                        new_value = value[data].replaceAll(result_all[0], getValueInMultiArray(elements, result_all[1].split('.')))
                    }
                    obj_tmp[data] = new_value;
                }
            } else {
                obj_tmp[data] = value[data];
            }
        }
    })

    return obj_tmp;
}

/**
 * Permet de recup valeur dans un array multidim
 * @param element
 * @param data_arr_call
 * @returns {string|*}
 */
function getValueInMultiArray(element, data_arr_call){
    let current = element;

    data_arr_call.forEach((key) => {
        if(typeof current[key] !== "undefined")
            current = current[key];
    })

    if((typeof current === "undefined") || (typeof current === "object" && data_arr_call[0] !== "list_variables" ))
        return ""
    else
        return current;
}
